import * as React from 'react';
import Layout from '../../components/layout/Layout';
import Navbar from '../../components/navbar/navbar';
import SEO from '../../components/seo/seo';
import { SegmentProvider } from '../../components/analytics/SegmentProvider';
import { withIntl } from '../../i18n';
import './terms.scss';

class Terms extends React.Component {
  private analytics: SegmentProvider

  constructor(props) {
    super(props)
    this.analytics = new SegmentProvider()
  }

  componentDidMount() {
    this.analytics.trackPageView()
  }

  render() {
    return <Layout>
        <SEO />
        <Navbar light={false} />
        <div className="mdc-layout-grid cm-content--min-height" id="cm-terms">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-8">
              <h1 className="mdc-typography--headline1" id="vertragsbedingungen-fu-r-die-nutzung-von-software-software-as-a-service-">
                Vertragsbedingungen für die Nutzung von Software (Software as a Service)
              </h1>
              <ol>
                <h2 className="mdc-typography--headline2" id="1-leistungen">
                  <li>Leistungen</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter stellt die vertragsgegenständlichen Leistungen, insbesondere den Zugang zur Software, in seinem Verfügungsbereich (ab
                      Schnittstelle Rechenzentrum zum Internet) bereit. Der Leistungsumfang, die Beschaffenheit, der Verwendungszweck und die
                      Einsatzbedingungen der vertragsgegenständlichen Leistungen ergeben sich aus der jeweiligen Leistungsbeschreibung, ergänzend aus der
                      Bedienungsanleitung der Software.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Darüber hinausgehende Leistungen, etwa die Entwicklung kundenindividueller Lösungen oder erforderliche Anpassungen bedürfen eines
                      gesonderten Vertrages.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter kann aktualisierte Versionen der Software bereitstellen. Der Anbieter wird den Kunden über aktualisierte Versionen und
                      entsprechende Nutzungshinweise auf elektronischem Wege informieren und diese entsprechend verfügbar machen.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="2-nutzungsumfang">
                  <li>Nutzungsumfang</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Die vertragsgegenständlichen Leistungen dürfen nur durch den Kunden und nur zu den im Vertrag vereinbarten Zwecken verwendet werden.
                      Der Kunde darf während der Laufzeit des Vertrages auf die vertragsgegenständlichen Leistungen mittels Tele-kommunikation (über das
                      Internet) zugreifen und mittels eines Browsers oder einer anderen geeigneten Anwendung (z.B. „App“) die mit der Software verbundenen
                      Funktionalitäten vertragsgemäß nutzen. Darüber hinausgehende Rechte, insbesondere an der Software oder den ggf. bereitgestellten
                      Infrastrukturleistungen im jeweiligen Rechenzentrum erhält der Kunde nicht. Jede weitergehende Nutzung bedarf der vorherigen
                      schriftlichen Zustimmung des Anbieters.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde darf die Software insbesondere nicht über den vereinbarten Nutzungs-umfang hinaus in Anspruch nehmen oder von Dritten nutzen
                      lassen oder sie Dritten zugänglich machen. Insbesondere ist es dem Kunden nicht gestattet, Software oder Teile davon zu
                      vervielfältigen, zu veräußern oder zeitlich begrenzt zu überlassen, zu vermieten oder zu verleihen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter ist berechtigt, angemessene technische Maßnahmen zum Schutz vor einer nicht vertragsgemäßen Nutzung zu treffen. Der
                      vertragsgemäße Einsatz der Leistungen darf dadurch nicht mehr als nur unwesentlich beeinträchtigt werden.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Im Falle eines vertragswidrigen Überschreitens des Nutzungsumfangs durch einen Nutzer oder im Falle einer unberechtigten
                      Nutzungsüberlassung hat der Kunde dem Anbieter auf Verlangen unverzüglich sämtliche ihm verfügbaren Angaben zur Geltendmachung der
                      Ansprüche wegen der vertragswidrigen Nutzung zu machen, insbesondere Name und Anschrift des Nutzers mitzuteilen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter kann die Zugangsberechtigung des Kunden widerrufen und / oder den Vertrag kündigen, wenn der Kunde die ihm gestattete
                      Nutzung erheblich überschreitet oder gegen Regelungen zum Schutz vor unberechtigter Nutzung verstößt. Damit verbunden kann der
                      Anbieter den Zugriff auf die vertraglichen Leistungen unterbrechen bzw. sperren. Der Anbieter hat dem Kunden vorher grundsätzlich eine
                      angemessene Nachfrist zur Abhilfe zu setzen. Der alleinige Widerruf der Zugangsberechtigung gilt nicht zugleich als Kündigung des
                      Vertrages. Den Widerruf der Zugangsberechtigung ohne Kündigung kann der Anbieter nur für eine angemessene Frist, maximal 3 Monate,
                      aufrechterhalten.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anspruch des Anbieters auf eine Vergütung für die über die vereinbarte Nutzung hinausgehende Nutzung bleibt unberührt.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde hat einen Anspruch auf Wiedereinräumung der Zugangsberechtigung und der Zugriffsmöglichkeit, nachdem er nachgewiesen hat,
                      dass er die vertragswidrige Nutzung eingestellt und eine zukünftige vertragswidrige Nutzung unterbunden hat.
                    </p>
                  </li>
                </ol>

                <h2 className="mdc-typography--headline2" id="3-verfu-gbarkeit-leistungsma-ngel">
                  <li>Verfügbarkeit, Leistungsmängel</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">Die Verfügbarkeit der bereitgestellten Leistungen ergibt sich aus der Leistungsbeschreibung.</p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Bei einer nur unerheblichen Minderung der Tauglichkeit der Leistungen zum vertragsgemäßen Gebrauch bestehen keine Ansprüche des Kunden
                      wegen Mängeln. Die verschuldensunabhängige Haftung des Anbieters wegen Mängeln, die bereits zum Zeitpunkt des Vertragsschlusses
                      vorhanden waren, ist ausgeschlossen.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="4-datenschutz">
                  <li>Datenschutz</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Soweit der Anbieter auf personenbezogene Daten des Kunden oder aus dessen Bereich zugreifen kann, wird er ausschließlich als
                      Auftragsverarbeiter tätig und diese Daten nur zur Vertragsdurchführung verarbeiten und nutzen. Der Anbieter wird Weisungen des Kunden
                      für den Umgang mit diesen Daten beachten. Der Kunde trägt etwaige nachteilige Folgen solcher Weisungen für die Vertragsdurch-führung.
                      Der Kunde wird mit dem Anbieter die Details für den Umgang des Anbieters mit den Daten des Kunden nach den datenschutzrechtlichen
                      Anforderungen vereinbaren.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde bleibt sowohl allgemein im Auftragsverhältnis als auch im datenschutzrechtlichen Sinne der Verantwortliche. Verarbeitet der
                      Kunde im Zusammenhang mit dem Vertrag personenbezogene Daten (einschließlich Erhebung und Nutzung), so steht er dafür ein, dass er
                      dazu nach den anwendbaren, insbesondere datenschutzrechtlichen Bestimmungen berechtigt ist und stellt im Falle eines Verstoßes den
                      Anbieter von Ansprüchen Dritter frei.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Für das Verhältnis zwischen Anbieter und Kunde gilt: Gegenüber der betroffenen Person trägt die Verantwortung für die Verarbeitung
                      (einschließlich Erhebung und Nutzung) personenbezogener Daten der Kunde, außer soweit der Anbieter etwaige Ansprüche der betroffenen
                      Person wegen einer ihm zuzurechnenden Pflichtverletzung zu vertreten hat. Der Kunde wird etwaige Anfragen, Anträge und Ansprüche der
                      betroffenen Person verantwortlich prüfen, bearbeiten und beantworten. Das gilt auch bei einer Inanspruchnahme des Anbieters durch die
                      betroffene Person. Der Anbieter wird den Kunden im Rahmen sei- ner Pflichten unterstützen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter gewährleistet, dass Daten des Kunden ausschließlich im Gebiet der Bundesrepublik Deutschland, in einem Mitgliedsstaat der
                      Europäischen Union oder in einem anderen Vertragsstaat des Abkommens über den Europäischen Wirtschafts-raum gespeichert werden, soweit
                      nichts anderes vereinbart ist.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="5-pflichten-des-kunden">
                  <li>Pflichten des Kunden</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde hat die ihm bzw. den Nutzern zugeordneten Zugangsberechtigungen sowie Identifikationsund Authentifikationsinformationen vor
                      dem Zugriff durch Dritte zu schützen und nicht an Unberechtigte weiterzugeben.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde ist verpflichtet, den Anbieter von sämtlichen Ansprüchen Dritter aufgrund von Rechtsverletzungen freizustellen, die auf
                      einer rechtswidrigen Verwendung des Leistungsgegenstands durch ihn beruhen oder mit seiner Billigung erfolgen. Erkennt der Kunde oder
                      muss er erkennen, dass ein solcher Verstoß droht, besteht die Pflicht zur unverzüglichen Unterrichtung des Anbieters.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde hat vom Anbieter zur Verfügung gestellte Möglichkeiten zu nutzen, seine Daten in seinem originären Verantwortungsbereich zu
                      sichern.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="6-vertragswidrige-nutzung-schadensersatz">
                  <li>Vertragswidrige Nutzung, Schadensersatz</li>
                </h2>
                <p className="mdc-typography--body1">
                  Für jeden Fall, in dem im Verantwortungsbereich des Kunden unberechtigt eine vertragsgegenständliche Leistung in Anspruch genommen wird,
                  hat der Kunde jeweils Schadensersatz in Höhe derjenigen Vergütung zu leisten, die für die vertragsgemäße Nutzung im Rahmen der für diese
                  Leistung geltenden Min- destvertragsdauer angefallen wäre. Der Nachweis, dass der Kunde die unberechtigte Nutzung nicht zu vertreten hat
                  oder kein oder ein wesentlich geringerer Schaden vorliegt, bleibt dem Kunden vorbehalten. Der Anbieter bleibt berechtigt, einen
                  weitergehenden Schaden geltend zu machen.
                </p>
                <h2 className="mdc-typography--headline2" id="7-sto-rungsmanagement">
                  <li>Störungsmanagement</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter wird Störungsmeldungen des Kunden entgegennehmen, den vereinbarten Störungskategorien (Ziffer 7.3) zuordnen und anhand
                      dieser Zuordnung die vereinbarten Maßnahmen zur Analyse und Bereinigung von Störungen durchführen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter wird während seiner üblichen Geschäftszeiten ordnungsgemäße Störungsmeldungen des Kunden entgegennehmen und jeweils mit
                      einer Kennung versehen. Auf Anforderung des Kunden bestätigt ihm der Anbieter den Eingang einer Störungsmeldung unter Mitteilung der
                      vergebenen Kennung.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Soweit nichts anderes vereinbart ist, wird der Anbieter entgegengenommene Störungsmeldungen nach erster Sichtung einer der folgenden
                      Kategorien zuordnen:
                    </p>
                    <ul>
                      <li className="mdc-typography--body1">
                        Schwerwiegende Störung: Die Störung beruht auf einem Fehler der vertragsgegenständlichen Leistungen, der die Nutzung der
                        vertragsgegenständlichen Leistungen, insbesondere der Software, unmöglich macht oder nur mit schwerwiegenden Einschränkungen
                        erlaubt. Der Kunde kann dieses Problem nicht in zumutbarer Weise umgehen und deswegen unaufschiebbare Aufgaben nicht erledigen.
                      </li>
                      <li className="mdc-typography--body1">
                        Sonstige Störung: Die Störung beruht auf einem Fehler der vertragsgegenständlichen Leistungen, der die Nutzung der
                        vertragsgegenständlichen Leistungen, insbesondere der Software, durch den Kunden mehr als nur unwesentlich einschränkt, ohne dass
                        eine schwerwiegende Störung vorliegt.
                      </li>
                      <li className="mdc-typography--body1">
                        Sonstige Meldung: Störungsmeldungen, die nicht in die Kategorien a) und b) fallen, werden den sonstigen Meldungen zugeordnet.
                        Sonstige Meldungen werden vom Anbieter nur nach den dafür getroffenen Vereinbarungen behandelt.
                      </li>
                    </ul>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Bei Meldungen über schwerwiegende Störungen und sonstige Störungen wird der Anbieter unverzüglich anhand der vom Kunden mitgeteilten
                      Umstände entsprechende Maßnahmen einleiten, um zunächst die Störungsursache zu lokalisieren. Stellt sich die mitgeteilte Störung nach
                      erster Analyse nicht als Fehler der vertragsgegenständlichen Leistungen, insbesondere der bereitgestellten Software, dar, teilt der
                      Anbieter dies dem Kunden unverzüglich mit. Sonst wird der Anbieter entsprechende Maßnahmen zur weitergehenden Analyse und zur
                      Bereinigung der mitgeteilten Störung veranlassen oder – bei Drittsoftware – die Störungsmeldung zusammen mit seinen Analyseergebnissen
                      dem Vertreiber oder Hersteller der Drittsoftware mit der Bitte um Abhilfe übermitteln. Der Anbieter wird dem Kunden ihm vorliegende
                      Maßnahmen zur Umgehung oder Bereinigung eines Fehlers der vertragsgegenständlichen Leistungen, insbesondere der bereitgestellten
                      Software, etwa Handlungsanweisungen oder Korrekturen der bereitgestellten Software, unverzüglich zur Verfügung stellen. Der Kunde wird
                      solche Maßnahmen zur Umgehung oder Bereinigung von Störungen unverzüglich übernehmen und dem Anbieter bei deren Einsatz etwa
                      verbleibende Störungen unverzüglich erneut melden.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="8-ansprechstelle-hotline-">
                  <li>Ansprechstelle (Hotline)</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Vertragliche Leistungen: Der Anbieter richtet eine Ansprechstelle für den Kunden ein (Hotline). Diese Stelle bearbeitet die Anfragen
                      des Kunden im Zusammenhang mit den technischen Einsatzvoraussetzungen und -bedingungen der bereitgestellten Software sowie zu
                      einzelnen funktionalen Aspekten.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Annahme und Bearbeitung von Anfragen: Voraussetzung für die Annahme und Bearbeitung von Anfragen ist, dass der Kunde gegenüber dem
                      Anbieter fachlich und technisch entsprechend qualifiziertes Personal benennt, das intern beim Kunden mit der Bearbeitung von Anfragen
                      der Anwender der bereitgestellten Software beauftragt ist. Der Kunde ist verpflichtet, nur über dieses dem Anbieter benannte Personal
                      Anfragen an die Hotline zu richten und dabei vom Anbieter gestellte Formulare zu verwenden. Die Hotline nimmt solche Anfragen per
                      E-Mail, Tele- fax und Telefon während der üblichen Geschäftszeiten des Anbieters entgegen. Die Hotline wird ordnungsgemäße Anfragen im
                      üblichen Geschäftsgang bearbeiten und soweit möglich beantworten. Die Hotline kann zur Beantwortung auf dem Kunden zugängliche
                      Dokumentationen und sonstige Ausbildungsmittel für die bereitgestellte Software verweisen. Soweit eine Beantwortung durch die Hotline
                      nicht oder nicht zeitnah möglich ist, wird der Anbieter – soweit dies ausdrücklich vereinbart ist – die Anfrage zur Bearbeitung
                      weiterleiten, insbesondere Anfragen zu nicht von ihm hergestellter Software. Weitergehende Leistungen der Hotline, etwa andere
                      Ansprechzeiten und -fristen sowie Rufbereitschaften oder Einsätze des Anbieters vor Ort beim Kunden sind vorab ausdrücklich zu
                      vereinbaren.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2">
                  <li>Vertragslaufzeit und Vertragsbeendigung</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    Die Erbringung der vertraglich vereinbarten Leistungen erfolgt ab dem im Vertrag bezeichneten Datum zunächst für die Dauer der im
                    Vertrag vereinbarten Laufzeit. Während dieser Mindestlaufzeit ist eine vorzeitige ordentliche Kündigung beidseitig ausgeschlossen.
                  </li>
                  <li className="mdc-typography--body1">
                    Der Vertrag kann mit einer Frist von drei Monaten gekündigt werden, frühestens zum Ablauf der Mindestlaufzeit. Geschieht dies nicht,
                    verlängert sich der Vertrag jeweils um ein weiteres Jahr, sofern er nicht mit einer Frist von 3 Monaten zum Ablauf des jeweiligen
                    Verlängerungszeitraums ordentlich gekündigt wurde.
                  </li>
                  <li className="mdc-typography--body1">
                    Das Recht jedes Vertragspartners zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.
                  </li>
                  <li className="mdc-typography--body1">
                    Jede Kündigungserklärung bedarf zu ihrer Wirksamkeit der Schriftform. Es gilt Ziffer 8.4 Allgemeinen Vertragsbedingungen.
                  </li>
                  <li className="mdc-typography--body1">
                    Der Kunde wird rechtzeitig vor Beendigung des Vertrages seine Datenbestände eigenverantwortlich sichern (etwa durch Download). Auf
                    Wunsch wird der Anbieter den Kunden dabei unterstützen, es gilt Ziffer 4.3 Allgemeinen Vertragsbedingungen. Eine Zugriffmöglichkeit des
                    Kunden auf diese Datenbestände wird nach Beendigung des Vertrages schon aus datenschutzrechtlichen Gründen regelmäßig nicht mehr gegeben
                    sein.
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="10-geltung">
                  <li>Geltung</li>
                </h2>
                <p className="mdc-typography--body1">
                  Ergänzend gelten die Allgemeinen Vertragsbedingungen.
                  <br />
                  Stand 1. Mai 2018
                </p>
              </ol>
              <br />
              <br />
              <h1 className="mdc-typography--headline1" id="allgemeine-vertragsbedingungen">
                Allgemeine Vertragsbedingungen
              </h1>
              <ol>
                <h2 className="mdc-typography--headline2" id="1-vergu-tung-zahlung-leistungsschutz-termine">
                  <li>Vergütung, Zahlung, Leistungsschutz, Termine</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Soweit nichts anderes vereinbart ist, wird die Vergütung nach Aufwand zu den bei Vertragsschluss allgemein gültigen Preisen des
                      Anbieters berechnet. Vergütungen sind grundsätzlich Netto-Preise zuzüglich gesetzlich anfallender Umsatzsteuer. Der Anbieter kann
                      monatlich abrechnen. Werden Leistungen nach Aufwand vergütet, dokumentiert der Anbieter die Art und Dauer der Tätigkeiten und
                      übermittelt diese Dokumentation mit der Rechnung.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Alle Rechnungen sind grundsätzlich spätestens 14 Kalendertage nach Zugang frei Zahlstelle ohne Abzug zu zahlen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde kann wegen Mängeln nur aufrechnen oder Zahlungen zurückbehalten, soweit ihm tatsächlich Zahlungsansprüche wegen Sachoder
                      Rechtsmängeln der Leistung zustehen. Wegen sonstiger Mängelansprüche kann der Kunde Zahlungen nur zu einem unter Berücksichtigung des
                      Mangels verhältnismäßigen Teil zurückbehalten. Ziffer 4.1 gilt entsprechend. Der Kunde hat kein Zurückbehaltungsrecht, wenn sein
                      Mangelanspruch verjährt ist. Im Übrigen kann der Kunde nur mit unbestrittenen oder rechtskräftig festgestellten Forderungen aufrechnen
                      oder eine Zurückbehaltung ausüben.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter behält sich das Eigentum und einzuräumende Rechte an den Leistungen bis zur vollständigen Bezahlung der geschuldeten
                      Vergütung vor, berechtigte Mängeleinbehalte gemäß Ziffer 1.3. Satz 2 werden berücksichtigt. Weiterhin behält sich der Anbieter das
                      Eigentum vor bis zur Erfüllung aller seiner Ansprüche aus der Geschäftsbeziehung mit dem Kunden. Der Anbieter ist berechtigt, für die
                      Dauer eines Zahlungsverzugs des Kunden diesem die weitere Nutzung der Leistungen zu untersagen. Dieses Recht kann der Anbieter nur für
                      einen angemessenen Zeitraum geltend machen, in der Regel höchstens für 6 Monate. Darin liegt kein Rücktritt vom Vertrag. § 449 Abs. 2
                      BGB bleibt unberührt. Geben der Kunde oder dessen Abnehmer die Leistungen zurück, liegt in der Entgegennahme der Leistungen kein
                      Rücktritt des Anbieters, außer er hat den Rücktritt ausdrücklich erklärt. Gleiches gilt für die Pfändung der Vorbehaltsware oder von
                      Rechten an der Vorbehaltsware durch den Anbieter. Gegenstände unter Eigentumsoder Rechtsvorbehalt darf der Kunde weder verpfänden noch
                      sicherungsübereignen. Dem Kunden ist nur als Wiederverkäufer eine Weiterveräußerung im gewöhnlichen Ge- schäftsgang unter der
                      Bedingung gestattet, dass dem Anbieter vom Kunden dessen Ansprüche gegen seine Abnehmer im Zusammenhang mit der Weiterveräußerung
                      wirksam abgetreten worden sind und der Kunde seinem Abnehmer das Eigentum unter Vorbehalt der Zahlung überträgt. Der Kunde tritt durch
                      den vorliegenden Vertragsabschluss seine künftigen Ansprüche im Zusammenhang mit solchen Veräußerungen gegen seine Abnehmer
                      sicherungshalber an den Anbieter ab, der diese Abtretung hiermit annimmt. Soweit der Wert der Sicherungsrechte des Anbieters die Höhe
                      der gesicherten Ansprüche um mehr als 20 % übersteigt, wird der Anbieter auf Wunsch des Kunden einen entsprechenden Anteil der
                      Sicherungs- rechte freigeben.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde ist verpflichtet, bei einer zulässigen Übertragung von Nutzungsrechten an Lieferungen und Leistungen dem Empfänger deren
                      vertraglich vereinbarte Beschränkungen aufzuerlegen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Gleicht der Kunde eine fällige Forderung zum vertragsgemäßen Zahlungstermin ganz oder teilweise nicht aus, kann der Anbieter
                      vereinbarte Zahlungsziele für alle Forderungen widerrufen. Der Anbieter ist ferner berechtigt, weitere Leistungen nur gegen Vorkasse
                      oder gegen Sicherheit durch Erfüllungsbürgschaft eines in der Europäischen Union zugelassenen Kreditinstituts oder Kreditversicherers
                      vorzunehmen. Die Vorkasse hat den jeweiligen Abrechnungszeitraum oder – bei Einmalleistungen – deren Vergütung zu umfassen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Bei wirtschaftlichem Unvermögen des Kunden, seine Pflichten gegenüber dem Anbieter zu erfüllen, kann der Anbieter bestehende
                      Austauschverträge mit dem Kunden durch Rücktritt, Dauerschuldverhältnisse durch Kündigung fristlos beenden, auch bei einem
                      Insolvenzantrag des Kunden. § 321 BGB und § 112 InsO bleiben unberührt. Der Kunde wird den Anbieter frühzeitig schriftlich über eine
                      drohende Zahlungsunfähigkeit informieren.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Feste Leistungstermine sollen ausschließlich ausdrücklich in dokumentierter Form vereinbart werden. Die Vereinbarung eines festen
                      Leistungstermins steht unter dem Vorbehalt, dass der Anbieter die Leistungen seiner jeweiligen Vorlieferanten rechtzeitig und
                      vertragsgemäß erhält.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="2-zusammenarbeit-mitwirkungspflichten-vertraulichkeit">
                  <li>Zusammenarbeit, Mitwirkungspflichten, Vertraulichkeit</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Kunde und Anbieter benennen jeweils einen verantwortlichen Ansprechpartner. Die Kommunikation zwischen dem Kunden und dem Anbieter
                      erfolgt, soweit nichts anderes vereinbart ist, über diese Ansprechpartner. Die Ansprechpartner haben alle mit der Vertragsdurchführung
                      zusammenhängenden Entscheidungen unverzüglich herbeizuführen. Die Entscheidungen sind verbindlich zu dokumentieren.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde ist verpflichtet, den Anbieter soweit erforderlich zu unterstützen und in seiner Betriebssphäre alle zur ordnungsgemäßen
                      Auftragsausführung erforderlichen Voraussetzungen zu schaffen. Dazu wird er insbesondere notwendige Informationen zur Verfügung
                      stellen und nach Möglichkeit einen Remotezugang auf das Kundensystem ermöglichen. Soweit aus Sicherheitsgründen oder sonstigen Gründen
                      ein Remotezugang nicht möglich ist, verlängern sich davon betroffene Fristen angemessen; für weitere Auswirkungen werden die
                      Vertragspartner eine angemessene Regelung vereinbaren. Der Kunde sorgt ferner dafür, dass fachkundiges Personal für die Unterstützung
                      des Anbieters zur Verfügung steht. Soweit im Vertrag vereinbart ist, dass Leistungen vor Ort beim Kunden erbracht werden können,
                      stellt der Kunde auf Wunsch des Anbieters unentgeltlich ausreichende Arbeitsplätze und Arbeitsmittel zur Verfügung.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Soweit nichts anderes vereinbart ist, wird der Kunde für eine ordnungsgemäße Datensicherung und Ausfallvorsorge für Daten und
                      Komponenten (etwa Hardware, Software) sorgen, die deren Art und Bedeutung angemessen ist.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde hat Mängel unverzüglich in nachvollziehbarer und detaillierter Form unter Angabe aller für die Mängelerkennung und -analyse
                      zweckdienlichen Informationen schriftlich zu melden. Anzugeben sind dabei insbesondere die Arbeitsschritte, die zum Auftreten des
                      Mangels geführt haben, die Erscheinungs- form sowie die Auswirkungen des Mangels. Soweit nichts anderes vereinbart ist, werden dafür
                      die entsprechenden Formulare und Verfahren des Anbieters verwendet.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde wird den Anbieter bei Prüfung und Geltendmachung von Ansprüchen gegenüber anderen Beteiligten im Zusammenhang mit der
                      Leistungserbringung angemessen auf Anforderung unterstützen. Dies gilt insbesondere für Rückgriffsansprüche des Anbieters gegen
                      Vorlieferanten.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Die Vertragspartner sind verpflichtet, über Geschäftsund Betriebsgeheimnisse sowie über sonstige als vertraulich bezeichnete
                      Informationen, die im Zusammenhang mit der Vertragsdurchführung bekannt werden, Stillschweigen zu wahren. Die Weitergabe solcher
                      Informationen an Personen, die nicht an dem Abschluss, der Durchführung oder Abwicklung des Vertrags beteiligt sind, darf nur mit
                      schriftlicher Einwilligung des jeweils anderen Vertragspartners erfolgen. Soweit nichts anderes vereinbart ist, endet diese
                      Verpflichtung nach Ablauf von fünf Jahren nach Bekanntwerden der jeweiligen Information, bei Dauer- schuldverhältnissen jedoch nicht
                      vor deren Beendigung. Die Vertragspartner werden diese Verpflichtungen auch ihren Mitarbeitern und eventuell eingesetzten Dritten
                      auferlegen.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Den Vertragspartnern ist bekannt, dass eine elektronische und unverschlüsselte Kommunikation (z.B. per E-Mail) mit Sicherheitsrisiken
                      behaftet ist. Bei dieser Art der Kommunikation werden sie daher keine Ansprüche geltend machen, die durch das Fehlen einer
                      Verschlüsselung begründet sind, außer soweit zuvor eine Verschlüsselung vereinbart worden ist.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="3-sto-rungen-bei-der-leistungserbringung">
                  <li>Störungen bei der Leistungserbringung</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Wenn eine Ursache, die der Anbieter nicht zu vertreten hat, einschließlich Streik oder Aussperrung, die Termineinhaltung
                      beeinträchtigt („Störung“), verschieben sich die Termine um die Dauer der Störung, erforderlichenfalls einschließlich einer
                      angemessenen Wiederanlaufphase. Ein Vertragspartner hat den anderen Vertragspartner über die Ursache einer in seinem Bereich
                      aufgetretenen Störung und die Dauer der Verschiebung unverzüglich zu unterrichten.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Erhöht sich der Aufwand aufgrund einer Störung, kann der Anbieter auch die Vergütung des Mehraufwands verlangen, außer der Kunde hat
                      die Störung nicht zu vertreten und deren Ursache liegt außerhalb seines Verantwortungsbereichs.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Wenn der Kunde wegen nicht ordnungsgemäßer Leistung des Anbieters vom Vertrag zurücktreten und / oder Schadensersatz statt der
                      Leistung verlangen kann oder solches behauptet, wird der Kunde auf Verlangen des Anbieters innerhalb angemessen gesetzter Frist
                      schriftlich erklären, ob er diese Rechte geltend macht oder weiterhin die Leistungserbringung wünscht. Bei einem Rücktritt hat der
                      Kunde dem Anbieter den Wert zuvor bestehender Nutzungsmöglichkeiten zu erstatten; gleiches gilt für Verschlechterungen durch
                      bestimmungsgemäßen Gebrauch. Gerät der Anbieter mit der Leistungserbringung in Verzug, ist der Schadensund Aufwendungsersatz des
                      Kunden wegen des Verzugs für jede vollendete Woche des Verzugs beschränkt auf 0,5 % des Preises für den Teil der vertraglichen
                      Leistung, der auf Grund des Verzugs nicht genutzt werden kann. Die Verzugshaftung ist begrenzt auf insgesamt höchstens 5 % der
                      Vergütung für sämtliche, vom Verzug betroffene vertragliche Leistungen; bei Dauerschuldverhältnissen bezogen auf die Vergütung für die
                      jeweils betroffenen Leistungen für das volle Kalenderjahr. Ergänzend und vorrangig gilt ein bei Vertragsabschluss vereinbarter
                      Prozentsatz der bei Vertragsabschluss vereinbarten Vergütung. Dies gilt nicht, soweit ein Verzug auf grober Fahrlässigkeit oder
                      Vorsatz des Anbieters beruht.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Bei einer Verzögerung der Leistung hat der Kunde im Rahmen der gesetzlichen Bestimmungen ein Rücktrittsrecht nur, wenn die Verzögerung
                      vom Anbieter zu vertreten ist. Macht der Kunde wegen der Verzögerung berechtigt Schadensoder Aufwendungsersatz statt der Leistung
                      geltend, so ist er berechtigt, für jede vollendete Woche der Verzögerung 1 % des Preises für den Teil der vertraglichen Leistung zu
                      verlangen, der auf Grund der Verzögerung nicht genutzt werden kann, jedoch insgesamt höchstens 10 % dieses Preises; bei
                      Dauerschuldverhältnissen bezogen auf die Vergütung für die jeweils betroffenen Leistungen für das volle Kalenderjahr. Ergänzend und
                      vorrangig gilt ein bei Vertragsabschluss vereinbarter Prozentsatz der bei Vertragsabschluss vereinbarten Vergütung.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="4-sachma-ngel-und-aufwendungsersatz">
                  <li>Sachmängel und Aufwendungsersatz</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter leistet Gewähr für die vertraglich geschuldete Beschaffenheit der Leistungen. Für eine nur unerhebliche Abweichung der
                      Leistungen des Anbieters von der vertragsgemäßen Beschaffenheit bestehen keine Ansprüche wegen Sachmängeln. Ansprüche wegen Mängeln
                      bestehen auch nicht bei übermäßiger oder unsachgemäßer Nutzung, natürlichem Verschleiß, Versagen von Komponenten der Systemumgebung,
                      nicht reproduzierbaren oder anderweitig durch den Kunden nachweisbaren Softwarefehlern oder bei Schäden, die aufgrund besonderer
                      äußerer Einflüsse entstehen, die nach dem Vertrag nicht vorausgesetzt sind. Dies gilt auch bei nachträglicher Veränderung oder
                      Instandsetzung durch den Kunden oder Dritte, außer diese erschwert die Analyse und die Beseitigung eines Sachmangels nicht. Für
                      Schadensersatz- und Aufwendungsersatzansprüche gilt Ziffer 6 ergänzend.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Die Verjährungsfrist für Sachmangelansprüche beträgt ein Jahr ab dem gesetzlichen Verjährungsbeginn. Die gesetzlichen Fristen für den
                      Rückgriff nach § 478 BGB bleiben unberührt. Gleiches gilt, soweit das Gesetz gemäß § 438 Abs. 1 Nr. 2 oder § 634a Abs. 1 Nr. 2 BGB
                      längere Fristen vorschreibt, bei einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des Anbieters, bei arglistigem
                      Verschweigen eines Mangels sowie in den Fällen der Verletzung des Lebens, des Körpers oder der Gesundheit sowie für Ansprüche aus dem
                      Produkthaftungsgesetz. Die Bearbeitung einer Sachmangelanzeige des Kunden durch den Anbieter führt nur zur Hemmung der Verjährung,
                      soweit die gesetzlichen Voraussetzungen dafür vorliegen. Ein Neubeginn der Verjährung tritt dadurch nicht ein. Eine Nacherfüllung
                      (Neulieferung oder Nachbesserung) kann ausschließlich auf die Verjährung des die Nacherfüllung auslösenden Mangels Einfluss haben.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">Der Anbieter kann Vergütung seines Aufwands verlangen, soweit</p>
                    <ul>
                      <li className="mdc-typography--body1">
                        er aufgrund einer Meldung tätig wird, ohne dass ein Mangel vorliegt, außer der Kunde konnte mit zumutbarem Aufwand nicht erkennen,
                        dass kein Mangel vorlag, oder
                      </li>
                      <li className="mdc-typography--body1">
                        eine gemeldete Störung nicht reproduzierbar oder anderweitig durch den Kunden als Mangel nachweisbar ist, oder
                      </li>
                      <li className="mdc-typography--body1">
                        zusätzlicher Aufwand wegen nicht ordnungsgemäßer Erfüllung der Pflichten des Kunden (siehe auch Ziffern 2.2, 2.3, 2.4 und 5.2)
                        anfällt.
                      </li>
                    </ul>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="5-rechtsma-ngel">
                  <li>Rechtsmängel</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Für Verletzungen von Rechten Dritter durch seine Leistung haftet der Anbieter nur, soweit die Leistung vertragsgemäß und insbesondere
                      in der vertraglich vereinbarten, sonst in der vorgesehenen Einsatzumgebung unverändert eingesetzt wird. Der Anbieter haftet für
                      Verletzungen von Rechten Dritter nur innerhalb der Europäischen Union und des Europäischen Wirtschaftsraumes sowie am Ort der
                      vertragsgemäßen Nutzung der Leistung. Ziffer 4.1 Satz 1 gilt entsprechend.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Macht ein Dritter gegenüber dem Kunden geltend, dass eine Leistung des Anbieters seine Rechte verletzt, benachrichtigt der Kunde
                      unverzüglich den Anbieter. Der Anbieter und ggf. dessen Vorlieferanten sind berechtigt, aber nicht verpflichtet, soweit zulässig die
                      geltend gemachten Ansprüche auf eigene Kosten abzuwehren. Der Kunde ist nicht berechtigt, Ansprüche Dritter anzuerkennen, bevor er dem
                      Anbieter angemessen Gelegenheit gegeben hat, die Rechte Dritter auf andere Art und Weise abzuwehren.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Werden durch eine Leistung des Anbieters Rechte Dritter verletzt, wird der Anbieter nach eigener Wahl und auf eigene Kosten{' '}
                    </p>
                    <ul>
                      <li className="mdc-typography--body1">dem Kunden das Recht zur Nutzung der Leistung verschaffen oder</li>
                      <li className="mdc-typography--body1">die Leistung rechtsverletzungsfrei gestalten oder</li>
                      <li className="mdc-typography--body1">
                        die Leistung unter Erstattung der dafür vom Kunden geleisteten Vergütung (abzüglich einer angemessenen Nutzungsentschädigung)
                        zurücknehmen, wenn der Anbieter keine andere Abhilfe mit angemessenem Aufwand erzielen kann. Die Interessen des Kunden werden dabei
                        angemessen berücksichtigt.
                      </li>
                    </ul>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Ansprüche des Kunden wegen Rechtsmängeln verjähren entsprechend Ziffer 4.2. Für Schadensersatzund Aufwendungsersatzansprüche des
                      Kunden gilt Ziffer 6 ergänzend, für zusätzlichen Aufwand des Anbieters gilt Ziffer 4.3 entsprechend.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="6-allgemeine-haftung-des-anbieters">
                  <li>Allgemeine Haftung des Anbieters</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">Der Anbieter haftet dem Kunden stets</p>
                    <ul>
                      <li className="mdc-typography--body1">
                        für die von ihm sowie seinen gesetzlichen Vertretern oder Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursachten Schäden,
                      </li>
                      <li className="mdc-typography--body1">nach dem Produkthaftungsgesetz und</li>
                      <li className="mdc-typography--body1">
                        für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die der Anbieter, seine gesetzlichen Vertreter oder
                        Erfüllungsgehilfen zu vertreten haben.
                      </li>
                    </ul>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter haftet bei leichter Fahrlässigkeit nicht, außer soweit er eine wesentliche Vertragspflicht verletzt hat, deren Erfüllung
                      die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht oder deren Verletzung die Erreichung des Vertragszwecks
                      gefährdet und auf deren Einhaltung der Kunde regelmäßig vertrauen darf. Diese Haftung ist bei Sachund Vermögensschäden auf den
                      vertragstypischen und vorhersehbaren Schaden beschränkt. Dies gilt auch für entgangenen Gewinn und ausgebliebene Einsparungen. Die
                      Haftung für sonstige entfernte Folgeschäden ist ausgeschlossen. Für einen einzelnen Schadensfall ist die Haftung auf den Vertragswert
                      begrenzt, bei laufender Vergütung auf die Höhe der Vergütung pro Vertragsjahr, jedoch nicht auf weniger als € 50.000. Für die
                      Verjährung gilt Ziffer 4.2 entsprechend. Die Vertragspartner können bei Vertragsabschluss eine weitergehende Haftung, üblicherweise
                      gegen eine gesonderte Vergütung, schriftlich vereinbaren. Vorrangig ist eine individuell vereinbarte Haftungssumme. Die Haftung gemäß
                      Ziffer 6.1 bleibt von diesem Absatz unberührt. Ergänzend und vorrangig ist die Haftung des Anbieters wegen leichter Fahrlässigkeit aus
                      dem jeweiligen Vertrag und seiner Durchführung auf Schadensund Aufwendungsersatz unabhängig vom Rechtsgrund insgesamt begrenzt auf den
                      in diesem Vertrag vereinbarten Prozentsatz der bei Vertragsabschluss ver- einbarten Vergütung. Die Haftung gemäß Ziffer 6.1 b) bleibt
                      von diesem Absatz unberührt.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Aus einer Garantieerklärung haftet der Anbieter nur auf Schadensersatz, wenn dies in der Garantie ausdrücklich übernommen wurde. Diese
                      Haftung unterliegt bei leichter Fahrlässigkeit den Beschränkungen gemäß Ziffer 6.2.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Bei notwendiger Wiederherstellung von Daten oder Komponenten (etwa Hardware, Software) haftet der Anbieter nur für denjenigen Aufwand,
                      der für die Wiederherstellung bei ordnungsgemäßer Datensicherung und Ausfallvorsorge durch den Kunden erforderlich ist. Bei leichter
                      Fahrlässigkeit des Anbieters tritt diese Haftung nur ein, wenn der Kunde vor dem Störfall eine der Art der Daten und Komponenten
                      angemessene Datensicherung und Ausfallvorsorge durchgeführt hat. Dies gilt nicht, soweit dies als Leistung des Anbieters vereinbart
                      ist.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Für Aufwendungsersatzansprüche und sonstige Haftungsansprüche des Kunden gegen den Anbieter gelten Ziffern 6.1 bis 6.4 entsprechend.
                      Ziffern 3.3 und 3.4 bleiben unberührt.
                    </p>
                  </li>
                </ol>
                <h2 className="mdc-typography--headline2" id="7-datenschutz">
                  <li>Datenschutz</li>
                </h2>
                <p className="mdc-typography--body1">
                  Der Kunde wird mit dem Anbieter datenschutzrechtlich notwendige Vereinbarungen für den Umgang mit personenbezogenen Daten abschließen.
                </p>
                <h2 className="mdc-typography--headline2" id="8-sonstiges">
                  <li>Sonstiges</li>
                </h2>
                <ol>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Kunde wird für die Lieferungen oder Leistungen anzuwendende Import-und Export-Vorschriften eigenverantwortlich beachten,
                      insbesondere solche der USA. Bei grenzüberschreitender Lieferung oder Leistung trägt der Kunde anfallende Zölle, Gebühren und sonstige
                      Abgaben. Der Kunde wird gesetzliche oder behördliche Verfahren im Zusammenhang mit grenzüberschreitenden Lieferungen oder Leistungen
                      eigenverantwortlich abwickeln, außer soweit anderes ausdrücklich vereinbart ist.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">Es gilt deutsches Recht. Die Anwendung des UN-Kaufrechts ist ausgeschlossen.</p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Der Anbieter erbringt seine Leistungen unter Zugrundelegung seiner Allgemeinen Geschäftsbedingungen (AGB). AGB des Kunden finden keine
                      Anwendung, auch wenn der Anbieter solchen nicht ausdrücklich widersprochen hat. Die Annahme der Leistungen durch den Kunden gilt als
                      Anerkennung der AGB des Anbieters unter Ver- zicht auf AGB des Kunden. Andere Bedingungen sind nur verbindlich, wenn der Anbieter sie
                      schriftlich anerkannt hat; ergänzend gelten dann die AGB des Anbieters.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Änderungen und Ergänzungen dieses Vertrages sollen nur schriftlich vereinbart werden. Soweit Schriftform vereinbart ist (z.B. für
                      Kündigungen, Rücktritt), genügt Textform nicht.
                    </p>
                  </li>
                  <li className="mdc-typography--body1">
                    <p className="mdc-typography--body1">
                      Gerichtsstand gegenüber einem Kaufmann, einer juristischen Person des öffentlichen Rechts oder einem öffentlich-rechtlichen
                      Sondervermögen ist der Sitz des Anbieters. Der Anbieter kann den Kunden auch an dessen Sitz verklagen.
                    </p>
                  </li>
                </ol>
                <br />
                <p className="mdc-typography--body1">Stand 1. Mai 2018</p>
              </ol>
            </div>
          </div>
        </div>
      </Layout>
  }
}

export default withIntl(Terms)
